export const validateEmail = (emailAddress: string) => {
  if (emailAddress === '') return false

  // Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Basic_validation
  const regEx = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

  if (regEx.test(emailAddress)) {
    return true
  }

  return false
}
