import firebase from 'firebase/app'
import 'firebase/functions'

const config = {
  apiKey: process.env.FIREBASE_API_KEY || '',
  authDomain: process.env.FIREBASE_AUTH_DOMAIN || '',
  databaseURL: process.env.FIREBASE_DATABASE_URL || '',
  projectId: process.env.FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.FIREBASE_STOREAGE_BUCKET || '',
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.FIREBASE_APP_ID || '',
  measurementId: process.env.FIREBASE_MEASUREMENT_ID || ''
}

let instance: firebase.app.App

const getFirebase = () => {
  if (typeof window !== 'undefined') {
    if (instance) return instance

    instance = firebase.initializeApp(config)

    return instance
  }

  return null
}

export default getFirebase
