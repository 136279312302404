import {
  makeStyles,
  createStyles,
  Typography,
  Button,
  Box,
  Container,
  TextField,
  Grid,
  CircularProgress
} from '@material-ui/core'
import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Carousel from 'react-material-ui-carousel'
import { Link } from 'gatsby'
import Gigs from '../components/gigs'
import { validateEmail } from '../services/genericService'
import { addContact } from '../services/firebaseService'

const useStyles = makeStyles(theme =>
  createStyles({
    mainSection: {
      marginTop: 30,
      height: 'calc(100vh - 106px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      background: 'linear-gradient(135deg, #56276b, #a578b9)',
      [theme.breakpoints.up('sm')]: {
        marginTop: 30,
        height: 'calc(100vh - 114px)'
      }
    },
    banner: {
      // background: 'linear-gradient(135deg, #56276b, #a578b9)',
      paddingTop: 160,
      paddingBottom: 160,
      color: 'white',
      '& button': {
        color: theme.palette.primary.main
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'ghostwhite'
        },
        '&:hover fieldset': {
          borderColor: 'white'
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white'
        }
      }
    },
    landingWrapper: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: 100,
        paddingLeft: 100
      }
    },
    textField: {
      marginBottom: 20,
      [`& fieldset`]: {
        borderRadius: 26
      },
      [`& input`]: {
        color: 'white',
        width: 300,
        fontSize: 16,
        [theme.breakpoints.up('sm')]: {
          fontSize: 18
        }
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: 0
      }
    },
    links: {
      textDecoration: 'none',
      color: theme.palette.primary.main
    },
    actionButton: {
      textTransform: 'none',
      borderRadius: 26,
      fontWeight: 400,
      fontSize: 18,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24
      }
    },
    contributeSection: {
      paddingTop: 60,
      paddingBottom: 60,
      backgroundColor: '#EEE9F1',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 100,
        paddingBottom: 100
      }
    },
    contributeLeading: {
      paddingTop: 60,
      [theme.breakpoints.up('lg')]: {
        paddingRight: 200,
        paddingLeft: 200
      }
    },
    contributeWrapper: {
      marginTop: 60,
      [theme.breakpoints.up('lg')]: {
        paddingRight: 100,
        paddingLeft: 100
      }
    },
    carouselSection: {
      paddingTop: 60,
      paddingBottom: 60,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 140,
        paddingBottom: 140
      }
    },
    carousel: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 100,
        paddingRight: 100
      }
    },
    error: {
      marginTop: 10,
      color: 'orange'
    },
    success: {
      color: 'yellowgreen',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 200,
        paddingRight: 200
      }
    }
  })
)

const IndexPage = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [formError, setFormError] = useState<string | undefined>(undefined)
  const [hasSubscribed, setHasSubscribed] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmitEmail = async () => {
    setIsSubmitting(true)

    const res = validateEmail(email)
    if (!res) {
      throw new Error('Invalid email address format')
    }

    try {
      await addContact(email)
      setIsSubmitting(false)
      setHasSubscribed(true)
    } catch (err) {
      setIsSubmitting(false)
      if (err.code === 'already-exists') {
        setFormError('This email address is already subscribed to HaloDAO!')
      } else if (err.code === 'failed-precondition') {
        setFormError('Please enter a valid email address.')
      } else {
        setFormError('Uh oh, something went wrong. Please try again later.')
      }
    }
  }

  return (
    <Layout>
      <SEO title="Home" />

      <section className={classes.mainSection} id="main">
        <Container>
          <div className={classes.banner}>
            <Box className={classes.landingWrapper}>
              <Typography variant="h3" align="center">
                Be one of the first few to own a piece of the Southeast Asian
                Stablecoin Economy
              </Typography>

              <Box textAlign="center" mt={8}>
                {hasSubscribed ? (
                  <Typography variant="h6" className={classes.success}>
                    Thank you for subscribing to HaloDAO! We will keep you
                    posted for important updates & announcements!
                  </Typography>
                ) : (
                  <>
                    <form
                      method="POST"
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmitEmail()
                      }}
                    >
                      <Grid container justify="center" spacing={3}>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            type="email"
                            name="email"
                            placeholder="Enter email address"
                            className={classes.textField}
                            value={email}
                            onChange={e => {
                              setEmail(e.target.value)
                            }}
                            disabled={isSubmitting}
                          ></TextField>
                          {formError !== undefined && (
                            <Box className={classes.error}>
                              <Typography>{formError}</Typography>
                            </Box>
                          )}
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            className={classes.actionButton}
                            color="secondary"
                            type="submit"
                            onClick={e => {
                              e.preventDefault()
                              handleSubmitEmail()
                            }}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <>
                                <CircularProgress size={26} />
                                &nbsp;Submitting...
                              </>
                            ) : (
                              <>Get Early Updates</>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </>
                )}
              </Box>
            </Box>
          </div>
        </Container>
      </section>

      <section className={classes.carouselSection} id="carousel">
        <Container>
          <Carousel className={classes.carousel}>
            <Box key={1}>
              <Typography variant="h3" align="center">
                Widening Financial Inclusion in Southeast Asia with local
                stablecoins
              </Typography>
            </Box>
            <Box key={2}>
              <Typography variant="h3" align="center">
                Building self sovereign financial infrastructure for those that
                need it the most
              </Typography>
            </Box>
            <Box key={3}>
              <Typography variant="h3" align="center">
                Widening Financial Inclusion in Southeast Asia with local
                stablecoins
              </Typography>
            </Box>
          </Carousel>
          <Box mt={6} textAlign="center">
            <Link to="/whitepaper" className={classes.links}>
              <Button
                variant="outlined"
                endIcon={<ChevronRight />}
                className={classes.actionButton}
                color="primary"
              >
                Read our Whitepaper
              </Button>
            </Link>
          </Box>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
